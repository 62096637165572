.custom-tooltip {
  color: #181d1f;
  font-size: 14px;
  position: absolute;
  width: initial;
  height: initial;
  pointer-events: none;
  transition: opacity 1s;
  background: #edf2f9;
  border-color: #181d1f;
  opacity: 80%;
  border-radius: 5px;
}

.custom-tooltip.ag-tooltip-hiding {
  opacity: 0;
}

.custom-tooltip p {
  margin: 5px;
  white-space: nowrap;
}

.custom-tooltip p:first-of-type {
  font-weight: 500;
}