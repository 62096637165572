.accordion-header {
    background: transparent !important;
    border: none !important ;
    padding: 0 !important;
}


.accordion-item:last-of-type {
    background: transparent !important;
    border: unset !important;
    padding: 0 !important;
}

.accordion {
    background: transparent !important;
    border: unset !important;
    padding: 0 !important;
}
.accordion-collapse collapse {
    border:none !important;
}
.accordion-item:last-of-type .accordion-button.collapsed{
    padding:0 !important;
    background:transparent !important;
}

.accordion-item:first-of-type .accordion-button{
    background:transparent !important;
    box-shadow: none !important;
}
.accordion-item:first-of-type .accordion-button{
    padding:0 !important;
}
.accordion-body{
    padding:0.5rem !important;
}