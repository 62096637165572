
#ChangeServicesLoader {
    background: none;
    text-align: center;
    border: none;
    margin:0;
    position: absolute;   
    top: 20%;
    left:50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    
}

#ChangeServicesLoader > div{
    border:none;
    background:none;
}


.form-control {
    width: 100% !important;
}

