
.groupValues:hover {
    background: #748194;
    color:white;
    cursor:pointer;
}

@media(max-width:500px){
    .group-button{
        margin-top:2rem;
    }
}