.clickable-icon {
  cursor: pointer;
}

.icon-box {
  width: 1.35rem;
  height: 1.35rem;
}

@media (max-width: 769px) {
  .card-header-accordion .font-weight-medium {
    font-size: .9rem;
  }

  .accordion-card-body * {
    font-size: .9rem !important;
  }

  .icon-box {
    width: 1rem;
    height: 1rem;
  }


  .spinner-border-sm {
    width: 1rem !important;
    height: 1rem !important;
  }
}

@media (max-width: 400px) {
  .card-header-accordion .font-weight-medium {
    font-size: .7rem;
  }

  .accordion-card-body * {
    font-size: .7rem !important;
  }
}