.modaldialog {
    max-width: unset !important;
    margin: unset !important;
    margin-right: -17px !important;
}

@media(max-width:500px){
    .hideSIM {
        display: none !important;
    }
   

}
.hideSIM {
    justify-content: end;
}
@media(min-width:1200px){
    .hideSIM {
        justify-content: center
    }
   
}

@media(max-width:1024px) {
    .rateplan {
        margin-top: 2rem !important;
    }
}