body {
}
.usage-calendar {
    border: none;
    width: unset;
    line-height: 2rem;
}


.usage-modal-header {
    border: none !important;
    padding: 0;
}

abbr[data-original-title], abbr[title] {
    text-decoration: underline solid !important;
}


.modal-header {
    padding: 0.7rem 2rem !important;
    align-content: center !important;
    align-items: center !important;
}

.usage-input-field {
    width: 70% !important;
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
    margin-bottom: 0 !important;
    position: relative;
}


.summary-threshold-field {
    position: relative;
}

    .summary-threshold-field:after {
        content: 'MB';
        display: block;
        font-weight: bold;
        position: absolute;
        top: 0;
        right: 1rem;
        font-size: 1.2rem;
    }

.react-calendar__navigation__arrow {
    background-color: transparent !important;
    font-size: 2rem !important;
}

@media (max-width: 500px) {
    .usage-date-filter-container {
        margin-left: -2rem;
    }
}

.selector-root {
    width: 10rem;
    border: 1px solid #b6c1d2;
    display: flex;
    justify-content: space-between;
}

.selector-text {
    padding: 0.5rem;
    font-weight: bold;
}

.selector-button {
    color: black;
    border: none;
    padding: 0;
    height: 2.5rem;
    border-radius: unset !important;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
}

.usage-apply-button {
    width: 7rem;
    font-size: 0.9rem !important;
}

.usage-filter-button {
    width: 8rem;
    background-color: #2C7BE5 !important;
    border: none !important;
    padding: 0.5rem !important;
}

.react-datepicker .react-datepicker__header {
    background-color: #fff !important;
    border: 0;
    color: #0b1727 !important;
    font-weight: 400;
}

.react-datepicker {
    background-color: transparent !important;
    border: 1px solid #d8e2ef !important;
}

.react-datepicker__month-container {
    background: #fff !important;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    color: #9da9bb !important;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
    width: 2.2rem !important;
}

@media (max-width: 900px) {
    .usage-filter-root {
        flex-direction: column !important;
    }
}

@media (max-width: 690px) {
    .offcanvas.offcanvas-top {
        height: 100% !important;
        width: 100% !important;
        left: unset !important;
    }
}

@media (min-width:1100px) {
    .offcanvas.offcanvas-top {
        height: 40% !important;
    }
}

@media (min-width:620px) {
    .offcanvas.offcanvas-top {
        height: 50% !important;
    }
}

@media (Min-width:1531px) {
    .offcanvas.offcanvas-top {
        height: 53% !important;
    }
}


@media (orientation: landscape) and (max-width:900px) {

    .offcanvas.offcanvas-top {
        height: 100% !important;
        width: 100% !important;
        left: unset !important;
    }
}

.offcanvas.offcanvas-top {
    width: 70%;
    display: flex;
    left: 16%;
    padding: 0.4rem;
}

@media (max-width: 690px) {
    .usage-report-date-root, .threshold-container-root {
        flex-direction: column;
        margin-left: -2rem;
    }
}

@media (max-width: 690px) {
    .usage-date-selector-root {
        margin-top: 3rem;
    }
}

.usage-threshold {
    height: 2.5rem !important;
    margin-top: 2.2rem !important;
}




@media (max-width:600px) {
    .form-control {
        width: 75% !important;
    }

    .usage-container {
        padding: 1rem !important;
    }

    .date-container {
    }

    .usage-icon-container {
        margin-bottom: 3rem !important;
    }

    .buttons-root-container {
        margin-right: 1rem;
    }
}

@media (max-width:980px) {
    .date-container {
        margin-top: 1rem !important;
        margin-left: -1.2rem !important;
    }

    .usage-icon-container {
        left: -1rem !important;
    }
}


.usage-container {
    padding: 3rem;
}






.buttons-root-container {
    margin-right: 1rem;
}

.pulse::before {
    content: '';
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0.2rem;
    bottom: 0;
    background-color: #2c7be55c;
    border-radius: 100%;
    transition: opacity .3s, transform .3s;
    animation: pulse-animation 1s cubic-bezier(0.24, 0, 0.38, 1) infinite;
    z-index: -1;
    left: -1px;
}

@keyframes pulse-animation {
    0% {
        opacity: 1;
        transform: scale(1);
    }

    50% {
        opacity: 0;
        transform: scale(1.5);
    }

    100% {
        opacity: 0;
        transform: scale(1.5);
    }
}

.date-font {
    font-size: 0.85rem !important;
}

ag-cell-wrapper {
    width: 100% !important;
}

.icon-table-container {
    align-items: center;
    width: 100% !important;
    justify-content: space-around !important;
}

.ag-cell-value {
    width: 100% !important;
}

.usage-cell-render-root {
    display: flex;
    position: relative;
}

.ag-cell {
    padding-left: unset !important;
}

.custom-toast {
}

.Toastify__toast--info {
    background: red !important;
}

.Toastify__toast-theme--colored.Toastify__toast--info {
    background: red !important;
}



.usage-pulse::before {
    content: '';
    display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    top: 0.1rem;
    bottom: 0;
    background-color: #2c7be55c;
    border-radius: 100%;
    transition: opacity .3s, transform .3s;
    animation: pulse-animation 1s cubic-bezier(0.24, 0, 0.38, 1) infinite;
    z-index: -1;
}

@media (max-width:740px) {
    .usage-info {
        display: none;
    }

    .usage-pulse::before {
        content: none !important;
    }
}

.Toastify__progress-bar {
    background-color: rgb(44, 123, 229) !important;
}

.Toastify__close-button > svg {
    height: 20px !important;
    width: 20px !important;
    color: black !important;
}

.usage-header {
    margin-right: 3rem;
    top: 0.5rem;
    position: relative;
}

.filter-icon-usage {
    position: relative;
    top: 0.4rem;
}

@media (max-width:500px) {
    .usage-header {
        margin-right: 1rem;
    }
}

.custom-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
    width: 40% !important;
    font-size: 0.8rem;
}