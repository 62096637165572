
.form-check-input[type=checkbox] {
    height: 20px;
    width: 20px;
    margin-left: 0px;
}

.LabelsHeadder {
    font-size: .9573em;
    letter-spacing: 0.03em;
    font-weight: bold;
}

.gheight{
    height:auto;
}

.reactdatepickerwrapp {
    display: inline-block;
    padding: 0;
    border: 0;
    /* width: 100%; */
}
