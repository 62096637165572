.grid {
  height: 75vh;
}

.ag-header-cell-text {
  color: #344050!important;
}

.ag-header {
  background-color: #edf2f9 !important;
}

.ag-cell {
  color: #5e6e82 !important;
}

.ag-row {
  background-color: #fff !important;
}

.ag-menu {
  background-color: #edf2f9 !important;
}

.sim-number-cell {
  color: #2c7be5 !important;
}

#custom-showcancelled-switch {
    height: 16px !important;
    width: 32px !important;
    margin-left: -40px !important;
}

