.side-modal p {
  margin-bottom: .2em;
}

.side-modal {
  width: 17em !important;
}

.side-modal-body {
  background-color: #edf2f9;
}

.reset-password-link {
  color: #2c7be5;
  cursor: pointer;
}

.reset-password-link:hover {
  text-decoration: underline;
}