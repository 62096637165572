.active-button, .not-active-button {
    /*box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;*/
    padding: 0.4rem;
    display: inline-block;
    height: 2.7rem;
    width: 10rem!important ;
    border: none !important;
}


.active-button span {
    color: white;
    font-size: 0.9rem;
    vertical-align:middle;
 }

.not-active-button {
    background-color: white !important;
}

.not-active-button span {
 color: black;
 font-size: 0.9rem;
 vertical-align:middle;
 }

.active-button {
 background-color: #2c7be5 !important;
 color:white !important;
}

.disbale-button {
}

.live-data-title {
    white-space: nowrap;
    color: #3f5670;
    font-weight: bold;
}

.live-data-content {
    white-space: nowrap;
    font-size: 0.9rem;
    color: #585c60
}
.inventory-headline {
    color: black;
    font-size: 1.3rem;
    font-weight: bold;
    margin-top: 1rem;
    white-space: nowrap;
}

.live-data-header {
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 1rem;
    white-space: nowrap;
}
.center-loading {
    height: 100vh;
    display: flex;
    justify-content: center;
}

.spinner {
    margin-top: 50vh;
}

@media ( max-width:991px){
    .asset-infotmation-root{
        flex-direction: column-reverse !important;
    }
    .asset-container {
        margin-bottom: 2rem !important;
    }

}
@media(max-width:600px){
    
}
@media(max-width:600px) {
    .data-container {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    }

.input-field {
    font-size: 0.8rem !important;
    border: solid 0.5px #87a3c3 !important;
    height: 2.2rem !important;
}





