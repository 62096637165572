/*@media screen and (min-width: 1px) {*/
/*@import "common.css" screen;*/
@keyframes nope {
  0% {
    transform: translate(0);
  }

  20% {
    transform: translate(3em);
  }

  40% {
    transform: translate(-3em);
  }

  60% {
    transform: translate(3em);
  }

  80% {
    transform: translate(-3em);
  }

  100% {
    transform: translate(0);
  }
}

@-webkit-keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.Login {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: visible;
  z-index: 100;
  margin-top: 10em;
}

.Login form {
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transition: transform .3s ease;
  position: relative;
  border-radius: 10px;
  padding: 1% 4%;
  margin: auto auto;
  z-index: 2000;
  top: 30%;
  left: 0;
  right: 0;
  bottom: 100%;
  background: #f1f5f9 no-repeat center center fixed;
}

@media (min-width: 250px) and (max-width: 550px) {
  .Login form {
    width: 75%;
  }
}

@media (min-width: 551px) {
  .Login form {
    width: 500px;
  }
}

.Login .form.invalid {
  background: #d55f5f no-repeat center center fixed;
  animation: nope .6s linear;
}

.Login .form.flip {
  z-index: 2;
  backface-visibility: hidden;
  transform: rotateY(180deg);
  transition: transform 0.6s ease;
  transform-style: preserve-3d;
  animation: gradient .3s ease infinite;
}

.Login .form.forgotform {
  display: none;
}

.Login .form button {
  background-color: dodgerblue;
  border-radius: 30px;
  margin-top: 1.3em;
  color: #fff;
  text-align: center;
  font-size: 1.4em;
  font-weight: 200;
  padding: 0.5em;
  cursor: pointer;
  user-select: none;
  display: block;
  float: none;
  border: none;
}

.Login .form .forgot forgetbutton {
}

.logo-vertical {
  height: 6.5rem;
}

#resetpassform .reset-password-submit:disabled {
  opacity: .2;
  cursor: auto;
}

.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: dodgerblue;
  color: dodgerblue;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: .5s;
}

.dot-flashing::before, .dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: dodgerblue;
  color: dodgerblue;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: dodgerblue;
  color: dodgerblue;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: dodgerblue;
  }
  50%,
  100% {
    background-color: #1e90ff00;
  }
}

.loading-text {
  text-align: center;
  color: dodgerblue;
}