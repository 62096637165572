.chart-card {
  height: 18em;
}

.chart-inside-card {
  width: 100%;
  height: 100%;
}

.chart-card tspan {
  font-size: 12px;
}

.chart-container {
  height: 100%;
  width: 100%;
  display: flex;
}

.chart-container * {
  margin: auto;
}